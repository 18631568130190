@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Urbanist";
  scroll-behavior: smooth;
}

.search::-webkit-search-cancel-button {
  color: #2b8c34;
}

table {
  width: 100%;
}

th {
  font-family: "Urbanist";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2b8c34;
  text-align: left;
  margin-right: 16px;
  margin-bottom: 24px;
}

td {
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #5c715e;
  margin-right: 16px;
}
/* tbody:hover {
  background-color: #edf7ee;
} */
.success-status-badge {
  background: #2b8c34;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.success-badge {
  background: #dbffdf;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2b8c34;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.pending-badge {
  background: #fff3ce;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #d5a407;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.error-badge {
  background: #fdded4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b92043;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.notcalled-badge {
  background: #fdded4;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #b92043;
  padding: 4px 16px;
  gap: 2px;
  width: 112px;
}

.dispatched-badge {
  background: #c9f7fc;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0066af;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.na-badge {
  background: #ecefec;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #96a397;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.platinum-badge {
  background: #4b4e68;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.silver-badge {
  background: #4b7295;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.bronze-badge {
  background: #e86454;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.gold-badge {
  background: #f9b43b;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.copper-badge {
  background: #6a1505;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.info-badge {
  background: #c9f7fc;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #0066af;
  padding: 4px 16px;
  gap: 2px;
  width: fit-content;
}

.complete-badge {
  background: #2b8c34;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  padding: 4px 8px;
  gap: 2px;
  width: fit-content;
}

.count-badge {
  background: #eceeec;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #344335;
  padding: 4px 8px;
  gap: 2px;
  width: fit-content;
}
.activity_log::-webkit-scrollbar {
  width: 4px;
}

.activity_log::-webkit-scrollbar-thumb {
  background-color: #ecefec;
  border-radius: 4px;
}

.campaignScroll::-webkit-scrollbar {
  width: 0.5px;
}
.call2orderOverflow::-webkit-scrollbar {
  width: 6px;
}
.call2orderOverflow::-webkit-scrollbar-track {
  background: #f7f7f7;
  border-radius: 8px;
}

/* Handle */
.call2orderOverflow::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 8px;
}

/* width */
.sidebar_scroll::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.sidebar_scroll::-webkit-scrollbar-track {
  background: #f7f7f7;
}

/* Handle */
.sidebar_scroll::-webkit-scrollbar-thumb {
  background: #fff;
}

/* Handle on hover */
.sidebar_scroll::-webkit-scrollbar-thumb:hover {
  background: #f7f7f7;
}

.farmers_scroll::-webkit-scrollbar {
  width: 1px;
}

.campaign-circular-progressbar-div {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #344335;
}

/* radio buttons */
input[type="radio"] {
  /* The native appearance is hidden */
  appearance: none;
  -webkit-appearance: none;

  /* For a circular appearance we need a border-radius. */
  border-radius: 50%;

  /* The background will be the radio dot's color. */
  background: #ffffff;

  /* The border will be the spacing between the dot and the outer circle */
  border: 1px solid #96a397;

  /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
  box-shadow: 0 0 0 1px #96a397;
}

input[type="radio"]:checked {
  box-shadow: 0 0 0 1px #2b8c34;
  background-color: #2b8c34;
  border: 3px solid #fff;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

input[type="file"] {
  display: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #96a397;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 5.3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2b8c34;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2b8c34;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* 2 */
.switch_two {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.switch_two input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider_two {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #96a397;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider_two:before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  left: 3px;
  bottom: 3.8px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 100%;
}

input:checked + .slider_two {
  background-color: #2b8c34;
}

input:focus + .slider_two {
  box-shadow: 0 0 1px #2b8c34;
}

input:checked + .slider_two:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider_two.round_two {
  border-radius: 70px;
}

.slider.round_two:before {
  border-radius: 50%;
}

.drop_area {
  height: 150px !important;
}
